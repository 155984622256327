import React, { Component, useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Dialog, DialogContent, DialogTitle, IconButton, Paper, Slide } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import Draggable from 'react-draggable';


const useStyles = makeStyles(theme => ({
    root: { padding: 20 },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    image: {
        width: '100%',
        boxShadow: '1px 1px 5px gray',
        borderRadius: 10
    },
    iconButton: {
        position: 'fixed',
        top: 8,
        right: 8,
        color: 'rgba(200,200,200,0.8)',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const QRReader = ({ onClose }) => {
    const classes = useStyles();
    const [data, setData] = useState('');
    const [show, setShow] = useState(true);
    const handleScan = (scanData) => {
        if (scanData) {
            setData(scanData)
            window.location = '/viewer'
        }
    }
    const handleError = () => {

    }
    useEffect(() => {
        if (!show) {
            onClose && onClose();
        }
    }, [show])
    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            transitionDuration={500}
            PaperProps={{style: {padding: 16, opacity: 0.9}}}
            keepMounted
            onClose={() => setShow(!show)}
        >
            <DialogTitle>
                {'Iniciar sesión'}
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
                <QrReader
                    showViewFinder={false}
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: 400, borderRadius: 8, overflow: 'hidden' }}
                />
                <p>{data}</p>
            </DialogContent>
        </Dialog>
    )
};

export default QRReader;