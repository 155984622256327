import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import {
  History,
  Achievements
} from './components'
import { Box, Typography } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import data from 'constants/history.json';
import GifPlayer from 'react-gif-player';


const useStyles = makeStyles(theme => ({
  root: {},
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    padding: 16,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const portland = !!(window.screen.availHeight > window.screen.availWidth);
  return (
    <div className={classes.root}>
      <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Section className={classes.section}>
          <History />
        </Section>
        {/* <Box padding={2} style={{ width: '100%', alignSelf: 'center', maxWidth: 800 }}>
          <Section className={classes.section}>
            <History />
          </Section>
          <SectionAlternate className={classes.section}>
            <Achievements />
          </SectionAlternate>
        </Box> */}
        
        <Box p={4} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)', }} />
        <Box p={4} style={{ height: '100%', backgroundColor: 'white', }} />
        <Carousel autoPlay infiniteLoop interval={5000} swipeable showThumbs={false} showStatus={false}>
          {
            data[1].content.map((item, index) => {
              return (
                <Box p={2} style={{ height: '100%', backgroundColor: 'white', }}>
                  <Box p={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography color='primary' variant='h5' style={{ fontWeight: 'bolder' }}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '30vw', textAlign: 'center', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
                      {item.content}
                    </Typography>
                  </Box>
                </Box>
              )
            })
          }
        </Carousel>
        <Box p={4} style={{ height: '100%', backgroundColor: 'white', }} />
        <Box p={4} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)', }} />
        <Box p={0} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)', }}>
          <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography color='primary' variant='h5' style={{ fontWeight: 'bolder', color: 'lightgray' }}>
              {data[2].content[0].title}
            </Typography>
          </Box>
          <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '40vw', textAlign: 'center', color: 'white', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
              {data[2].content[0].content}
            </Typography>
          </Box>
        </Box>
        <Box p={4} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)', }} />
        <Box p={4} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)', }} />
        <Box style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'rgba(50,50,50,0.9)', paddingTop: 24 }}>
          <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '30vw', fontWeight: 'bolder', color: 'lightgray', textAlign: 'center', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
            {data[4].title}
          </Typography>
        </Box>
        <Carousel autoPlay infiniteLoop interval={5000} swipeable showThumbs={false} showStatus={false}>
          {
            data[4].content.map((item, index) => {
              return (
                <Box p={2} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)' }}>
                  {item.title &&
                    <Box p={1} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='h5' style={{ fontWeight: 'bolder', color: 'white' }}>
                        {item.title}
                      </Typography>
                    </Box>
                  }
                  <Box p={2} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {item.url &&
                      <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={item.url} style={{ width: portland ? 200 : 200, boxShadow: '0px 0px 2px rgba(200,200,200,0.3)', backgroundColor: 'white', borderRadius: 10, padding: 20 }} />
                      </Box>}
                    <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '30vw', color: 'white', textAlign: 'center', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
                      {item.content}
                    </Typography>
                  </Box>
                  <Box p={1} style={{ display: 'flex', justifyContent: 'center' }}>

                  </Box>
                </Box>
              )
            })
          }
        </Carousel>
        <Box p={4} style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white' }}>
          <Typography color='primary' variant='h5' style={{ fontWeight: 'bolder' }}>
            {data[3].title}
          </Typography>
        </Box>

        <Box style={{ display: 'flex', overflow: 'scroll' }}>
        <img src={'images/quienessomos/cronograma.jpg'} style={{ height: 500, boxShadow: '0px 0px 2px rgba(200,200,200,0.3)' }} />
        </Box>
        {/* <Carousel autoPlay infiniteLoop interval={3000} showThumbs={false} showStatus={false}>
          {
            data[3].content.map((item, index) => {
              return (
                <Box p={1} style={{ height: '100%', backgroundColor: 'white', }}>
                  {item.title &&
                    <Box p={1} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography color='primary' variant='h5' style={{ fontWeight: 'bolder' }}>
                        {item.title}
                      </Typography>
                    </Box>
                  }
                  <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '30vw', textAlign: 'center', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
                      {item.content}
                    </Typography>
                  </Box>
                </Box>
              )
            })
          }
        </Carousel> */}
        <Box p={4} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)', }} />
        <Box style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'rgba(50,50,50,0.9)', paddingTop: 24 }}>
          <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '30vw', fontWeight: 'bolder', color: 'lightgray', textAlign: 'center', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
            {data[5].title}
          </Typography>
        </Box>
        <Carousel autoPlay infiniteLoop interval={5000} swipeable showThumbs={false} showStatus={false}>
          {
            data[5].content.filter(e => e.url && e.mensaje && !e.title.includes('DELIA')).map((item, index) => {
              return (
                <Box p={2} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)' }}>
                  {item.title &&
                    <Box p={1} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='h5' style={{ fontWeight: 'bolder', color: 'white' }}>
                        {item.title}{'\n'}
                        <Typography variant='h5' style={{ fontWeight: 'bolder', color: 'white', fontSize: 12 }}>
                        {item.subTitle}
                      </Typography>
                      </Typography>
                    </Box>
                  }
                  <Box p={2} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {item.url &&
                      <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box style={{ width: 250, height: 250, overflow: 'hidden', borderRadius: 125 }}>
                          <img src={`images/colaboradores/${item.url}`} width={80} style={{backgroundColor: 'white'}}/>
                          {/* <GifPlayer
                            gif={item.url}
                            still={item.url}
                          /> */}
                        </Box>
                        {/* <img src={item.url} style={{ width: portland ? 150 : 150, boxShadow: '0px 0px 2px rgba(200,200,200,0.3)', backgroundColor: 'white', borderRadius: 10 }} /> */}
                      </Box>}
                    <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '30vw', color: 'white', textAlign: 'center', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
                      {item.content}
                    </Typography>
                    <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='h6' style={{ maxWidth: portland ? '90%' : '30vw', color: 'white', textAlign: 'center', alignSelf: 'center', display: 'flex', msAlignSelf: 'center', whiteSpace: 'pre-wrap' }}>
                        {'"'}{item.mensaje}{'"'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box p={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  </Box>
                </Box>
              )
            })
          }
        </Carousel>
        <Box p={4} style={{ height: '100%', backgroundColor: 'rgba(50,50,50,0.9)', }} />
      </Box>


    </div>
  );
};

export default NotFound;
